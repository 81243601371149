<template>
    <aside id="leftMenu" :class="[s.leftMenu]">
        <div :class="s.container">
            <LeftMenuGroup
                id="ownedAccountsList"
                :is-owned="true"
                :accounts-order="ownedAccountsOrder"
                :accounts="ownedAccounts"
                :listShow="isOwnedAccountsShow"
                :showSearch="isOwnedAccountsShow"
                no-items-label="No trade accounts"
                @set-search-width="setOwnedAccountsHeaderMargin($event)"
                :class="[s.group, { [s.active]: isOwnedAccountsShow, active: isOwnedAccountsShow }]"
            >
                <template #header>
                    <div>
                        <p
                            id="showOwnedAccountsButton"
                            @click="() => setOwnedShow(!isOwnedAccountsShow)"
                            :class="s.headerText"
                        >
                            {{ $store.getters.localization.LeftMenu.Titles.Accounts }}
                            <Icon icon="arrow_down" :class="[s.arrow, { [s.rotated]: isOwnedAccountsShow }]" />
                        </p>
                        <p :class="s.valueText">
                            ≈ {{ quotationAssetCharacter + ownedAccountsTotal.getSeparatedDigits() }}
                        </p>
                    </div>
                </template>
                <template #account-settings>
                    <AccountsSettings v-if="isOwnedAccountsShow" />
                </template>
            </LeftMenuGroup>
            <hr />
            <LeftMenuGroup
                id="managedAccountsList"
                :is-owned="false"
                :accounts-order="managedAccountsOrder"
                :accounts="managedAccounts"
                :listShow="showMobileView ? true : isManagedAccountsShow"
                :showSearch="isManagedAccountsShow"
                no-items-label="No managed accounts"
                @set-search-width="setManagedAccountsHeaderMargin($event)"
                :class="[s.group, { [s.active]: isManagedAccountsShow, active: isManagedAccountsShow, }]"
            >
                <template #header>
                    <div :class="{ [s.mtL]: isManagedMarginNeeded }">
                        <p
                            id="showManagedAccountsButton"
                            @click="() => setManagedShow(!isManagedAccountsShow)"
                            :class="s.headerText"
                        >
                            {{ $store.getters.localization.LeftMenu.Titles.Manage }}
                            <Icon icon="arrow_down" :class="[s.arrow, { [s.rotated]: !isManagedAccountsShow }]" />
                        </p>
                        <p :class="s.valueText">
                            ≈ {{ quotationAssetCharacter + managedAccountsTotal.getSeparatedDigits() }}
                        </p>
                    </div>
                </template>
            </LeftMenuGroup>
            <hr />
            <LeftMenuInfo />
        </div>
    </aside>
</template>

<script>
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon.vue';
import LeftMenuInfo from 'Components/LeftMenu/LeftMenuInfo.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import { UnifiedExchangesTags } from 'Store/v2/Transfer';

import LeftMenuGroup from './LeftMenuGroup.vue';
import AccountsSettings from './LeftMenu.AccountSettings.vue';

export default {
    name: 'LeftMenu',
    components: {
        LeftMenuGroup,
        AccountsSettings,
        LeftMenuInfo,
        Icon,
    },
    data() {
        return {
            showSuspendedAccounts: false,
            showMobileView: false,
            isOwnedAccountsShow: true,
            isManagedAccountsShow: false,
            isOwnedMarginNeeded: false,
            isManagedMarginNeeded: false,
        };
    },
    computed: {
        ...mapGetters({
            activeOwnAccounts: 'Accounts/ownAccounts',
            suspendedOwnAccounts: 'Accounts/suspendedOwnAccounts',
            managedAccounts: 'Accounts/manageAccounts',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        ownedAccountsOrder() {
            return this.$store.state.Interface.config.ownedAccountsOrder;
        },
        managedAccountsOrder() {
            return this.$store.state.Interface.config.managedAccountsOrder;
        },
        ownedAccounts() {
            return this.showSuspendedAccounts ? this.suspendedOwnAccounts : this.activeOwnAccounts;
        },
        ownedAccountsTotal() {
            if (!this.ownedAccounts || this.ownedAccounts.length === 0) {
                return 0;
            }
            const idsSet = new Set();
            this.ownedAccounts.forEach((a) => {
                idsSet.add(a.id);
            });
            try {
                return this.$store.state.Balances.balances
                    .reduce((accum, { total, quotations, quotationAssetSymbols, accountId, placementName }) => {
                        const currentPlacementTag = this.$store.state.Placements.placementNamesToPlacementTags.get(placementName.toUpperCase());
                        const currentPlacementType = this.$store.state.Placements.placements.find(({ name }) => name.toUpperCase() === placementName.toUpperCase())?.type;
                        if (
                            !idsSet.has(accountId)
                            || (
                                UnifiedExchangesTags.has(currentPlacementTag)
                                && currentPlacementType !== 'crypto-spot'
                            )
                        ) {
                            return accum;
                        }

                        const quotationIndex = quotationAssetSymbols.findIndex((symbol) => symbol === this.quotationAssetSymbol);
                        return accum + (Number(total) * Number(quotations[quotationIndex]));
                    }, 0).toFixed(this.totalPrecision);
            } catch (error) {
                return 0;
            }
        },
        managedAccountsTotal() {
            if (!this.managedAccounts || this.managedAccounts.length === 0) {
                return 0;
            }
            const idsSet = new Set();
            this.managedAccounts.forEach((a) => {
                idsSet.add(a.id);
            });
            try {
                return this.$store.state.Balances.balances
                    .reduce((accum, { total, quotations, quotationAssetSymbols, accountId, placementName }) => {
                        const currentPlacementTag = this.$store.state.Placements.placementNamesToPlacementTags.get(placementName.toUpperCase());
                        const currentPlacementType = this.$store.state.Placements.placements.find(({ name }) => name.toUpperCase() === placementName.toUpperCase())?.type;
                        if (
                            !idsSet.has(accountId)
                            || (
                                UnifiedExchangesTags.has(currentPlacementTag)
                                && currentPlacementType !== 'crypto-spot'
                            )
                        ) {
                            return accum;
                        }
                        const quotationIndex = quotationAssetSymbols.findIndex((symbol) => symbol === this.quotationAssetSymbol);
                        return accum + (Number(total) * Number(quotations[quotationIndex]));
                    }, 0).toFixed(this.totalPrecision);
            } catch (error) {
                return 0;
            }
        },
        totalPrecision() {
            const quotationAssetSymbol = this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
            return calculatePrecision(quotationAssetSymbol);
        },
    },
    methods: {
        setOwnedAccountsHeaderMargin(isMarginNeeded) {
            this.isOwnedMarginNeeded = isMarginNeeded;
        },
        setManagedAccountsHeaderMargin(isMarginNeeded) {
            this.isManagedMarginNeeded = isMarginNeeded;
        },
        toggleMobileView() {
            this.showMobileView = !this.showMobileView;
        },
        setOwnedShow(value) {
            if (!this.showMobileView) {
                this.isOwnedAccountsShow = value;
                this.isOwnedMarginNeeded = false;
                localStorage.setItem('isOwnedAccountsShow', value);
            }
        },
        setManagedShow(value) {
            if (!this.showMobileView) {
                this.isManagedAccountsShow = value;
                this.isManagedMarginNeeded = false;
                localStorage.setItem('isManagedAccountsShow', value);
            }
        },
    },
    created() {
        const isOwnedAccountsShow = JSON.parse(localStorage.getItem('isOwnedAccountsShow'));
        const isManagedAccountsShow = JSON.parse(localStorage.getItem('isManagedAccountsShow'));

        if (isOwnedAccountsShow !== null && isManagedAccountsShow !== null) {
            this.setOwnedShow(isOwnedAccountsShow);
            this.setManagedShow(isManagedAccountsShow);
        } else {
            localStorage.setItem('isOwnedAccountsShow', true);
            localStorage.setItem('isManagedAccountsShow', false);
        }
    },
    watch: {
        isOwnedAccountsShow() {
            if (!this.isOwnedAccountsShow && !this.isManagedAccountsShow) {
                this.setManagedShow(true);
            }
        },
        isManagedAccountsShow() {
            if (!this.isManagedAccountsShow && !this.isOwnedAccountsShow) {
                this.setOwnedShow(true);
            }
        },
        showMobileView(value) {
            if (value) {
                this.isManagedAccountsShow = true;
                this.isOwnedAccountsShow = true;
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.leftMenu {
    position: fixed;
    height: calc(100% - var(--sb-header-height));
    background: var(--cl-white);
    width: var(--left-menu-width);
    overflow-x: visible;
    overflow-y: hidden;
    z-index: 80;
    & .container {
        display: flex;
        flex-direction: column;
        height: 100%;
        & .group {
            min-height: 64px;
            height: 64px;
            transition: height var(--transition);
            overflow-y: hidden;
            position: relative;
            &.active {
                height: calc(100% - 84px);
                overflow-y: auto;
            }
        }
    }
    & .headerText {
        font-weight: var(--fw-extrabold);
        font-size: var(--fs-m);
        line-height: var(--fs-m);
        text-transform: uppercase;
        color: var(--cl-violet);
        cursor: pointer;
    }
    & .valueText {
        font-weight: var(--fw-bold);
        font-size: var(--fs-m);
        line-height: var(--fs-m);
        color: var(--cl-gray);
    }
    & .mtL {
        margin-top: var(--m-xxxl);
    }
    & hr {
        border: none;
        color: var(--cl-white-background);
        background-color: var(--cl-white-background);
        height: 5px;
        margin: 0;
    }
    & .arrow {
        transition: transform var(--transition);
        &.rotated {
            transform: rotate(180deg);
        }
    }
}
</style>
