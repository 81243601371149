// This file was autogenerated. Please do not change.
export interface IPlaceOrderRequest {
    accountId?: string;
    assetPairId?: number;
    assetPairSymbol?: string;
    leverage?: number;
    marketPrice?: number;
    parentId?: number;
    parentType?: string;
    placementId?: number;
    placementName?: string;
    price?: number;
    quantity?: number;
    quoteQuantity?: number;
    side?: ('BUY' | 'SELL');
    timeInForce?: ('GTC' | 'FOK' | 'IOC');
    triggerPrice?: number;
    type?: ('LIMIT' | 'MARKET' | 'LIMIT_STOP' | 'MARKET_STOP');
}

export default class PlaceOrderRequest {
    readonly _accountId: string | undefined;

    /**
     * Example: AX5RL7F7P7C
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _assetPairId: number | undefined;

    /**
     * Example: 19
     */
    get assetPairId(): number | undefined {
        return this._assetPairId;
    }

    readonly _assetPairSymbol: string | undefined;

    /**
     * Example: BTC/USDT
     */
    get assetPairSymbol(): string | undefined {
        return this._assetPairSymbol;
    }

    readonly _leverage: number | undefined;

    /**
     * Example: 5
     */
    get leverage(): number | undefined {
        return this._leverage;
    }

    readonly _marketPrice: number | undefined;

    /**
     * Example: 40000
     */
    get marketPrice(): number | undefined {
        return this._marketPrice;
    }

    readonly _parentId: number | undefined;

    get parentId(): number | undefined {
        return this._parentId;
    }

    readonly _parentType: string | undefined;

    get parentType(): string | undefined {
        return this._parentType;
    }

    readonly _placementId: number | undefined;

    /**
     * Example: 3
     */
    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _price: number | undefined;

    /**
     * Example: 43000
     */
    get price(): number | undefined {
        return this._price;
    }

    readonly _quantity: number | undefined;

    /**
     * Example: 1.156925
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _quoteQuantity: number | undefined;

    get quoteQuantity(): number | undefined {
        return this._quoteQuantity;
    }

    readonly _side: ('BUY' | 'SELL') | undefined;

    /**
     * Example: SELL
     */
    get side(): ('BUY' | 'SELL') | undefined {
        return this._side;
    }

    readonly _timeInForce: ('GTC' | 'FOK' | 'IOC') | undefined;

    /**
     * Example: FOK
     */
    get timeInForce(): ('GTC' | 'FOK' | 'IOC') | undefined {
        return this._timeInForce;
    }

    readonly _triggerPrice: number | undefined;

    /**
     * Example: 41000
     */
    get triggerPrice(): number | undefined {
        return this._triggerPrice;
    }

    readonly _type: ('LIMIT' | 'MARKET' | 'LIMIT_STOP' | 'MARKET_STOP') | undefined;

    /**
     * Example: LIMIT_STOP
     */
    get type(): ('LIMIT' | 'MARKET' | 'LIMIT_STOP' | 'MARKET_STOP') | undefined {
        return this._type;
    }

    constructor(props: IPlaceOrderRequest) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.assetPairId === 'number') {
            this._assetPairId = props.assetPairId;
        }
        if (typeof props.assetPairSymbol === 'string') {
            this._assetPairSymbol = props.assetPairSymbol.trim();
        }
        if (typeof props.leverage === 'number') {
            this._leverage = props.leverage;
        }
        if (typeof props.marketPrice === 'number') {
            this._marketPrice = props.marketPrice;
        }
        if (typeof props.parentId === 'number') {
            this._parentId = props.parentId;
        }
        if (typeof props.parentType === 'string') {
            this._parentType = props.parentType.trim();
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.price === 'number') {
            this._price = props.price;
        }
        if (typeof props.quantity === 'number') {
            this._quantity = props.quantity;
        }
        if (typeof props.quoteQuantity === 'number') {
            this._quoteQuantity = props.quoteQuantity;
        }
        if (props.side) {
            this._side = props.side;
        }
        if (props.timeInForce) {
            this._timeInForce = props.timeInForce;
        }
        if (typeof props.triggerPrice === 'number') {
            this._triggerPrice = props.triggerPrice;
        }
        if (props.type) {
            this._type = props.type;
        }
    }

    serialize(): IPlaceOrderRequest {
        const data: IPlaceOrderRequest = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._assetPairId !== 'undefined') {
            data.assetPairId = this._assetPairId;
        }
        if (typeof this._assetPairSymbol !== 'undefined') {
            data.assetPairSymbol = this._assetPairSymbol;
        }
        if (typeof this._leverage !== 'undefined') {
            data.leverage = this._leverage;
        }
        if (typeof this._marketPrice !== 'undefined') {
            data.marketPrice = this._marketPrice;
        }
        if (typeof this._parentId !== 'undefined') {
            data.parentId = this._parentId;
        }
        if (typeof this._parentType !== 'undefined') {
            data.parentType = this._parentType;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._price !== 'undefined') {
            data.price = this._price;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = this._quantity;
        }
        if (typeof this._quoteQuantity !== 'undefined') {
            data.quoteQuantity = this._quoteQuantity;
        }
        if (typeof this._side !== 'undefined') {
            data.side = this._side;
        }
        if (typeof this._timeInForce !== 'undefined') {
            data.timeInForce = this._timeInForce;
        }
        if (typeof this._triggerPrice !== 'undefined') {
            data.triggerPrice = this._triggerPrice;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IPlaceOrderRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            assetPairId: !this._assetPairId ? true : typeof this._assetPairId === 'number',
            assetPairSymbol: !this._assetPairSymbol ? true : typeof this._assetPairSymbol === 'string',
            leverage: !this._leverage ? true : typeof this._leverage === 'number',
            marketPrice: !this._marketPrice ? true : typeof this._marketPrice === 'number',
            parentId: !this._parentId ? true : typeof this._parentId === 'number',
            parentType: !this._parentType ? true : typeof this._parentType === 'string',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            price: !this._price ? true : typeof this._price === 'number',
            quantity: !this._quantity ? true : typeof this._quantity === 'number',
            quoteQuantity: !this._quoteQuantity ? true : typeof this._quoteQuantity === 'number',
            triggerPrice: !this._triggerPrice ? true : typeof this._triggerPrice === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
