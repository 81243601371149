// This file was autogenerated. Please do not change.
export interface IBalance {
    accountId: string;
    assetId: number;
    assetSymbol: string;
    assetType: ('crypto-spot' | 'crypto-stable-coin' | 'fiat');
    blockchainName?: string;
    borrowable?: boolean;
    free: string;
    hold: string;
    id: string;
    placementId: number;
    placementName: string;
    placementType: ('self' | 'crypto-spot');
    positionsHold: string;
    quotation?: string;
    quotationAssetSymbol?: string;
    quotationAssetSymbols?: string[];
    quotations?: string[];
    total: string;
}

export default class Balance {
    readonly _accountId: string;

    /**
     * Example: A5Q98WZ7PDX
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _assetId: number;

    /**
     * Example: 17
     */
    get assetId(): number {
        return this._assetId;
    }

    readonly _assetSymbol: string;

    /**
     * Example: BTC
     */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _assetType: ('crypto-spot' | 'crypto-stable-coin' | 'fiat');

    /**
     * Example: crypto-spot
     */
    get assetType(): ('crypto-spot' | 'crypto-stable-coin' | 'fiat') {
        return this._assetType;
    }

    readonly _blockchainName: string | undefined;

    /**
     * Example: BSC
     */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _borrowable: boolean | undefined;

    get borrowable(): boolean | undefined {
        return this._borrowable;
    }

    readonly _free: number;

    /**
     * Example: 0
     */
    get free(): number {
        return this._free;
    }

    readonly _hold: number;

    /**
     * Example: 0
     */
    get hold(): number {
        return this._hold;
    }

    readonly _id: string;

    /**
     * Example: BQS7UK9V96U
     */
    get id(): string {
        return this._id;
    }

    readonly _placementId: number;

    /**
     * Example: 1
     */
    get placementId(): number {
        return this._placementId;
    }

    readonly _placementName: string;

    /**
     * Example: Single Broker
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _placementType: ('self' | 'crypto-spot');

    /**
     * Example: self
     */
    get placementType(): ('self' | 'crypto-spot') {
        return this._placementType;
    }

    readonly _positionsHold: number;

    /**
     * Example: 0
     */
    get positionsHold(): number {
        return this._positionsHold;
    }

    readonly _quotation: number | undefined;

    /**
     * Example: 0
     */
    get quotation(): number | undefined {
        return this._quotation;
    }

    readonly _quotationAssetSymbol: string | undefined;

    /**
     * Example: USD
     */
    get quotationAssetSymbol(): string | undefined {
        return this._quotationAssetSymbol;
    }

    readonly _quotationAssetSymbols: string[] | undefined;

    /**
     * Example: USD,BTC
     */
    get quotationAssetSymbols(): string[] | undefined {
        return this._quotationAssetSymbols;
    }

    readonly _quotations: string[] | undefined;

    /**
     * Example: 0,0
     */
    get quotations(): string[] | undefined {
        return this._quotations;
    }

    readonly _total: number;

    /**
     * Example: 0
     */
    get total(): number {
        return this._total;
    }

    constructor(props: IBalance) {
        this._accountId = props.accountId.trim();
        this._assetId = props.assetId;
        this._assetSymbol = props.assetSymbol.trim();
        this._assetType = props.assetType;
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.borrowable === 'boolean') {
            this._borrowable = props.borrowable;
        }
        this._free = Number(props.free);
        this._hold = Number(props.hold);
        this._id = props.id.trim();
        this._placementId = props.placementId;
        this._placementName = props.placementName.trim();
        this._placementType = props.placementType;
        this._positionsHold = Number(props.positionsHold);
        if (typeof props.quotation === 'string') {
            this._quotation = Number(props.quotation);
        }
        if (typeof props.quotationAssetSymbol === 'string') {
            this._quotationAssetSymbol = props.quotationAssetSymbol.trim();
        }
        if (props.quotationAssetSymbols) {
            this._quotationAssetSymbols = props.quotationAssetSymbols;
        }
        if (props.quotations) {
            this._quotations = props.quotations;
        }
        this._total = Number(props.total);
    }

    serialize(): IBalance {
        const data: IBalance = {
            accountId: this._accountId,
            assetId: this._assetId,
            assetSymbol: this._assetSymbol,
            assetType: this._assetType,
            free: String(this._free),
            hold: String(this._hold),
            id: this._id,
            placementId: this._placementId,
            placementName: this._placementName,
            placementType: this._placementType,
            positionsHold: String(this._positionsHold),
            total: String(this._total),
        };
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._borrowable !== 'undefined') {
            data.borrowable = this._borrowable;
        }
        if (typeof this._quotation !== 'undefined') {
            data.quotation = String(this._quotation);
        }
        if (typeof this._quotationAssetSymbol !== 'undefined') {
            data.quotationAssetSymbol = this._quotationAssetSymbol;
        }
        if (typeof this._quotationAssetSymbols !== 'undefined') {
            data.quotationAssetSymbols = this._quotationAssetSymbols;
        }
        if (typeof this._quotations !== 'undefined') {
            data.quotations = this._quotations;
        }
        return data;
    }

    toJSON(): IBalance {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            assetId: typeof this._assetId === 'number',
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            borrowable: !this._borrowable ? true : typeof this._borrowable === 'boolean',
            free: true,
            hold: true,
            id: typeof this._id === 'string' && !!this._id.trim(),
            placementId: typeof this._placementId === 'number',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            positionsHold: true,
            quotation: true,
            quotationAssetSymbol: !this._quotationAssetSymbol ? true : typeof this._quotationAssetSymbol === 'string',
            quotationAssetSymbols: !this._quotationAssetSymbols ? true : this._quotationAssetSymbols.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            quotations: !this._quotations ? true : this._quotations.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            total: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
