// This file was autogenerated. Please do not change.
export interface IMarginBorrowingsRequest {
    accountId: string;
    assetSymbol?: string;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementTag?: string;
}

export default class MarginBorrowingsRequest {
    readonly _accountId: string;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _assetSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementTag: string | undefined;

    /**
     * Example: BYBIT
     */
    get placementTag(): string | undefined {
        return this._placementTag;
    }

    constructor(props: IMarginBorrowingsRequest) {
        this._accountId = props.accountId.trim();
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementTag === 'string') {
            this._placementTag = props.placementTag.trim();
        }
    }

    serialize(): IMarginBorrowingsRequest {
        const data: IMarginBorrowingsRequest = {
            accountId: this._accountId,
        };
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementTag !== 'undefined') {
            data.placementTag = this._placementTag;
        }
        return data;
    }

    toJSON(): IMarginBorrowingsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementTag: !this._placementTag ? true : typeof this._placementTag === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
