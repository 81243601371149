import ApiError from 'Entities/ApiError';
import Borrowing from 'Entities/privatePresenter/Borrowing';
import Collateral from 'Entities/privatePresenter/Collateral';
import CollateralRequest from 'Entities/privatePresenter/CollateralRequest';
import MarginBorrowingsRequest from 'Entities/privatePresenter/MarginBorrowingsRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class MarginApi {
    static async privateMarginGetBorrowings(marginborrowingsrequest: MarginBorrowingsRequest, withHeaders?: boolean): Promise<{ data: Borrowing[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...marginborrowingsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateMarginGetBorrowings', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/margin/getBorrowings`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(marginborrowingsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Borrowing(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Margin/privateMarginGetBorrowings', { response: res, data: result, fields: [JSON.stringify({ marginborrowingsrequest })] });
    }

    static async privateMarginGetCollateral(collateralrequest: CollateralRequest, withHeaders?: boolean): Promise<{ data: Collateral; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...collateralrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateMarginGetCollateral', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/margin/getCollateral`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(collateralrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new Collateral(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Margin/privateMarginGetCollateral', { response: res, data: result, fields: [JSON.stringify({ collateralrequest })] });
    }
}
