export enum SocketNamespaces {
    defi = 'defi',
    defiPositions = 'defi_positions',
    balances = 'balances',
    orders = 'orders',
    trades = 'trades',
    orderbooks = 'orderbooks',
    candles = 'candles',
    transfers = 'transfers',
    accounts = 'accounts',
    notifications = 'notifications',
    publicdata = 'publicdata',
    futuresTrades = 'futures_trades',
    futuresOrders = 'futures_orders',
    futuresPositions = 'futures_positions',
    pnlUpdates = 'pnl_updates',
    stakingPositions = 'staking_positions',
    marginBorrowings = 'margin_borrowings',
}
