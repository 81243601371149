import { Action } from 'vuex';

import PublicApi from 'Apis/PublicData';
import Placement from 'Entities/publicPresenter/Placement';
import Blockchain from 'Entities/publicPresenter/Blockchain';
import Asset from 'Entities/publicPresenter/Asset';
import PlacementsRequest from 'Lib/entities/publicPresenter/PlacementsRequest';
import BlockchainsRequest from 'Lib/entities/publicPresenter/BlockchainsRequest';
import AssetsRequest from 'Lib/entities/publicPresenter/AssetsRequest';
import AccountsApi from 'Apis/Accounts';
import BalancesRequest from 'Entities/privatePresenter/BalancesRequest';
import WalletsApi from 'Apis/Wallets';
import TransferFeeRequestData, { ITransferFeeRequestData } from 'Entities/walletExecutor/TransferFeeRequestData';
import TransferRequestData, { ITransferRequestData } from 'Entities/walletExecutor/TransferRequestData';
import Balance from 'Entities/privatePresenter/Balance';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import { actionCreator, mutationCreator } from 'Store/utils';
import router from '@/router';
import { UPDATE_TRANSFER } from 'Store/v2/TransferHistory';
import TransferRequest from 'Entities/privatePresenter/TransferRequest';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import EmptyResult from 'Entities/walletExecutor/EmptyResult';

export const UnifiedExchangesTags = new Set([
    'OKEX',
    'OKEX_USDTM_FUTURES',
    'BYBIT',
    'BYBIT_FUTURES',
]);

export const TransferStatuses: Record<string, { layoutName: string; color: string; needAlert?: boolean; }> = {
    created: {
        layoutName: 'Created',
        color: 'white',
    },
    pending: {
        layoutName: 'Processing',
        color: 'yellow',
    },
    processing: {
        layoutName: 'Processing',
        color: 'yellow',
    },
    confirmed: {
        layoutName: 'Completed',
        color: 'green',
    },
    failed: {
        layoutName: 'Cancelled',
        color: 'gray',
    },
    recovering: {
        layoutName: 'Processing',
        color: 'yellow',
    },
    recovered: {
        layoutName: 'Cancelled',
        color: 'gray',
    },
    recovery_failed: {
        layoutName: 'Failed',
        color: 'red',
        needAlert: true,
    },
    manual_recovering: {
        layoutName: 'Failed',
        color: 'red',
        needAlert: true,
    },
    not_completed: {
        layoutName: 'Cancelled',
        color: 'gray',
    },
    cancelled: {
        layoutName: 'Cancelled',
        color: 'gray',
    },
};

export enum TransferTypes {
    Exchanges = 'Exchanges',
    Accounts = 'Accounts',
}

interface IAccountOrExchangeInfo {
    id: string,
    index: number,
}

interface ISetActiveAssetPayload {
    symbol: string,
    index: number,
}

interface IDoTransferProps {
    activeAccountId: string,
}

export interface TransferUI {
    from: string, // placement name
    to: string, // placement name
    blockchain: string | undefined, // blockchain name
    asset: string, // asset symbol
    currentAssetIndex: number, // asset index
    currentBlockchainIndex: number, // blockchain index
    transferType: TransferTypes,
    fromAccount: IAccountOrExchangeInfo,
    toAccount: IAccountOrExchangeInfo,
    fromExchange: IAccountOrExchangeInfo,
    toExchange: IAccountOrExchangeInfo,
    fromBalances: Balance[] | null,
    toBalances: Balance[] | null,
    quantity: number,
    fees: Record<string, any> | null | EmptyResult,
    feeSize: undefined | 'low' | 'medium' | 'high',
}

const state = {
    placements: new Map<string, Placement>(),
    blockchains: undefined as undefined | Map<string, Blockchain>,
    assets: undefined as undefined | Map<string, Asset>,
    ui: {
        fromBalances: null,
        toBalances: null,
        quantity: 0,
        fromAccount: {
            id: '',
            index: 0,
        } as IAccountOrExchangeInfo,
        toAccount: {
            id: '',
            index: 0,
        } as IAccountOrExchangeInfo,
        fromExchange: {
            id: '',
            index: 0,
        } as IAccountOrExchangeInfo,
        toExchange: {
            id: '',
            index: 0,
        } as IAccountOrExchangeInfo,
        blockchain: '',
        asset: '',
        feeSize: undefined,
        fees: null,
        currentAssetIndex: 0,
        currentBlockchainIndex: 0,
        transferType: TransferTypes.Exchanges,
    } as TransferUI,
};

export type TransferState = typeof state;

export enum TransferGetters {
    GET_PLACEMENTS = 'GET_PLACEMENTS',
    GET_BLOCKCHAINS = 'GET_BLOCKCHAINS',
    GET_ASSETS = 'GET_ASSETS',
    GET_TRANSFER_MIN_SIZE = 'GET_TRANSFER_MIN_SIZE',
    GET_BLOCKCHAIN_NEEDED = 'GET_BLOCKCHAIN_NEEDED',
}

type GettersReturn<G extends { [key in TransferGetters]: (...args: any) => any }> = { [key in keyof G]: ReturnType<G[TransferGetters]> };

interface Getters {
    GET_PLACEMENTS: (state: TransferState, getters: GettersReturn<Getters>) => Placement[];
    GET_BLOCKCHAINS: (state: TransferState, getters: GettersReturn<Getters>) => Blockchain[];
    GET_ASSETS: (state: TransferState, getters: GettersReturn<Getters>) => Asset[];
    GET_TRANSFER_MIN_SIZE: (state: TransferState, getters: GettersReturn<Getters>) => number;
    GET_BLOCKCHAIN_NEEDED: (state: TransferState, getters: GettersReturn<Getters>) => boolean;
}

const getters: Getters = {
    GET_PLACEMENTS(state) {
        return Array.from(state.placements?.values() || []);
    },
    GET_BLOCKCHAINS(state) {
        if (!state.assets) {
            return [];
        }
        return Array.from(state.blockchains?.values() || []).filter((b) => {
            let flag = false;
            if (state.assets?.get(state.ui.asset)) {
                state.assets.get(state.ui.asset)!.transferDetails!.forEach((d) => {
                    if (d.blockchainName === b.name) {
                        flag = true;
                    }
                });
            }
            return flag;
        });
    },
    GET_ASSETS(state) {
        return Array.from(state.assets?.values() || []);
    },
    GET_TRANSFER_MIN_SIZE(state) {
        const asset = state.assets?.get(state.ui.asset);
        if (!asset) {
            return 0;
        }
        const transferDetail = asset.transferDetails?.find((d) => d.blockchainName === state.ui.blockchain);
        if (!transferDetail || !transferDetail.transferMinSize) {
            return 0;
        }
        return Number(transferDetail.transferMinSize);
    },
    GET_BLOCKCHAIN_NEEDED(state) {
        const asset = state.assets?.get(state.ui.asset);
        if (!asset) {
            return false;
        }
        const { transferDetails } = asset;
        if (!transferDetails) {
            return false;
        }
        return !!transferDetails[0].blockchainName;
    },
};

export enum TransferMutations {
    SET_PLACEMENTS = 'SET_PLACEMENTS',
    SET_BLOCKCHAINS = 'SET_BLOCKCHAINS',
    SET_ASSETS = 'SET_ASSETS',
    SET_UI = 'SET_UI',
    SET_ACTIVE_ASSET = 'SET_ACTIVE_ASSET',
    SET_ACTIVE_BLOCKCHAIN = 'SET_ACTIVE_BLOCKCHAIN',
    SET_TO_ACCOUNT = 'SET_TO_ACCOUNT',
    SET_FROM_ACCOUNT = 'SET_FROM_ACCOUNT',
    SET_TO_EXCHANGE = 'SET_TO_EXCHANGE',
    SET_FROM_EXCHANGE = 'SET_FROM_EXCHANGE',
    SET_QUANTITY = 'SET_QUANTITY',
    SET_FEE_SIZE = 'SET_FEE_SIZE',
}

export const SET_PLACEMENTS = mutationCreator<Placement[]>('Transfer', TransferMutations.SET_PLACEMENTS);
export const SET_BLOCKCHAINS = mutationCreator<Blockchain[]>('Transfer', TransferMutations.SET_BLOCKCHAINS);
export const SET_ASSETS = mutationCreator<Asset[]>('Transfer', TransferMutations.SET_ASSETS);
export const SET_UI = mutationCreator<Partial<TransferUI>>('Transfer', TransferMutations.SET_UI);
export const SET_ACTIVE_ASSET = mutationCreator<ISetActiveAssetPayload>('Transfer', TransferMutations.SET_ACTIVE_ASSET);
export const SET_ACTIVE_BLOCKCHAIN = mutationCreator<number>('Transfer', TransferMutations.SET_ACTIVE_BLOCKCHAIN);
export const SET_TO_ACCOUNT = mutationCreator<IAccountOrExchangeInfo>('Transfer', TransferMutations.SET_TO_ACCOUNT);
export const SET_FROM_ACCOUNT = mutationCreator<IAccountOrExchangeInfo>('Transfer', TransferMutations.SET_FROM_ACCOUNT);
export const SET_TO_EXCHANGE = mutationCreator<IAccountOrExchangeInfo>('Transfer', TransferMutations.SET_TO_EXCHANGE);
export const SET_FROM_EXCHANGE = mutationCreator<IAccountOrExchangeInfo>('Transfer', TransferMutations.SET_FROM_EXCHANGE);
export const SET_QUANTITY = mutationCreator<number>('Transfer', TransferMutations.SET_QUANTITY);
export const SET_FEE_SIZE = mutationCreator<TransferUI['feeSize']>('Transfer', TransferMutations.SET_FEE_SIZE);

const mutations: Record<TransferMutations, (state: TransferState, ...args: any) => void> = {
    SET_PLACEMENTS(state, placements: ReturnType<typeof SET_PLACEMENTS>) {
        const map = new Map<string, Placement>();
        placements.payload.forEach((p) => map.set(p.name, p));
        state.placements = map;
    },
    SET_BLOCKCHAINS(state, blockchains: ReturnType<typeof SET_BLOCKCHAINS>) {
        const map = new Map<string, Blockchain>();
        blockchains.payload.forEach((b) => map.set(b.name, b));
        state.blockchains = map;
    },
    SET_ASSETS(state, assets: ReturnType<typeof SET_ASSETS>) {
        const map = new Map<string, Asset>();
        assets.payload.forEach((b) => map.set(b.symbol, b));
        state.assets = map;
    },
    SET_UI(state, ui: ReturnType<typeof SET_UI>) {
        state.ui = { ...(state.ui || {}), ...(ui.payload || {}) };
    },
    SET_ACTIVE_ASSET(state, { payload: { symbol, index } }: ReturnType<typeof SET_ACTIVE_ASSET>) {
        state.ui.currentAssetIndex = index;
        state.ui.asset = symbol;
        state.ui.blockchain = String(state.assets?.get(symbol)?.transferDetails![0].blockchainName);
        const blockchainsArray = Array.from(state.blockchains?.values() || []).filter((b) => {
            let flag = false;
            state.assets!.get(state.ui.asset)!.transferDetails!.forEach((d) => {
                if (d.blockchainName === b.name) {
                    flag = true;
                }
            });
            return flag;
        });
        blockchainsArray.findIndex((b) => b.name === state.ui.blockchain);
        state.ui.currentBlockchainIndex = blockchainsArray.findIndex((b) => b.name === state.ui.blockchain);
    },
    SET_ACTIVE_BLOCKCHAIN(state, blockchainIndex: ReturnType<typeof SET_ACTIVE_BLOCKCHAIN>) {
        state.ui.currentBlockchainIndex = blockchainIndex.payload;
        const blockchainsArray = Array.from(state.blockchains?.values() || []).filter((b) => {
            let flag = false;
            state.assets!.get(state.ui.asset)!.transferDetails!.forEach((d) => {
                if (d.blockchainName === b.name) {
                    flag = true;
                }
            });
            return flag;
        });
        state.ui.blockchain = blockchainsArray[blockchainIndex.payload].name;
    },
    SET_TO_ACCOUNT(state, props: ReturnType<typeof SET_TO_ACCOUNT>) {
        state.ui.toAccount.id = props.payload.id;
        state.ui.toAccount.index = props.payload.index;
    },
    SET_FROM_ACCOUNT(state, props: ReturnType<typeof SET_FROM_ACCOUNT>) {
        state.ui.fromAccount.id = props.payload.id;
        state.ui.fromAccount.index = props.payload.index;
    },
    SET_TO_EXCHANGE(state, props: ReturnType<typeof SET_TO_EXCHANGE>) {
        state.ui.toExchange.id = props.payload.id;
        state.ui.toExchange.index = props.payload.index;
    },
    SET_FROM_EXCHANGE(state, props: ReturnType<typeof SET_FROM_EXCHANGE>) {
        state.ui.fromExchange.id = props.payload.id;
        state.ui.fromExchange.index = props.payload.index;
    },
    SET_QUANTITY(state, quantity: ReturnType<typeof SET_QUANTITY>) {
        state.ui.quantity = quantity.payload;
    },
    SET_FEE_SIZE(state, size: ReturnType<typeof SET_FEE_SIZE>) {
        state.ui.feeSize = size.payload;
    },
};

export enum TransferActions {
    init = 'init',
    getPlacements = 'getPlacements',
    getBlockchains = 'getBlockchains',
    getAssests = 'getAssests',
    onUiUpdate = 'onUiUpdate',
    getBalance = 'getBalance',
    getTransferFees = 'getTransferFees',
    doTransfer = 'doTransfer'
}

export const init = actionCreator<void>('Transfer', TransferActions.init);
export const getPlacements = actionCreator<null>('Transfer', TransferActions.getPlacements);
export const getBlockchains = actionCreator<null>('Transfer', TransferActions.getBlockchains);
export const onUiUpdate = actionCreator<TransferUI>('Transfer', TransferActions.onUiUpdate);
export const getAssests = actionCreator<null>('Transfer', TransferActions.getAssests);
export const getBalance = actionCreator<string>('Transfer', TransferActions.getBalance);
export const getTransferFees = actionCreator<string>('Transfer', TransferActions.getTransferFees);
export const doTransfer = actionCreator<IDoTransferProps>('Transfer', TransferActions.doTransfer);

const actions: Record<TransferActions, Action<TransferState, any>> = {
    async init({ dispatch }) {
        await dispatch(getPlacements(null, true));
        await dispatch(getBlockchains(null, true));
        await dispatch(getAssests(null, true));
    },
    async getPlacements({ commit, dispatch }) {
        try {
            const req = new PlacementsRequest({});
            const { data: resp } = await PublicApi.publicGetPlacements(req);
            const transferablePlacements = resp
                .filter((r) => r.type !== 'crypto-spot-decentralized')
                .sort((a) => (a.type === 'self' ? -1 : 1));
            if (transferablePlacements.length >= 2) {
                const [{ name: from }, { name: to }] = transferablePlacements;
                commit(SET_UI({ from, to } as Partial<TransferUI>, true));
            }
            commit(SET_PLACEMENTS(transferablePlacements, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during getting placements', { root: true });
            }
        }
        return Promise.resolve();
    },
    async getBlockchains({ commit, dispatch }) {
        try {
            const { data: resp } = await PublicApi.publicGetBlockchains(new BlockchainsRequest({}));
            commit(SET_BLOCKCHAINS(resp, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during getting blockchains', { root: true });
            }
        }
    },
    async onUiUpdate({ dispatch, commit }, payload: ReturnType<typeof onUiUpdate>) {
        await commit(SET_UI(payload.payload, true));
        await dispatch(getAssests(null, true));
    },
    async getAssests({ commit, state, dispatch }) {
        if (!state.ui) {
            console.error('failed to get from/to in transfer');
            return;
        }
        try {
            const { data: resp } = await PublicApi.publicGetAssets(new AssetsRequest({
                fromPlacementName: state.ui.transferType === 'Exchanges' ? state.ui.fromExchange.id : state.ui.fromAccount.id,
                toPlacementName: state.ui.transferType === 'Exchanges' ? state.ui.toExchange.id : state.ui.fromAccount.id,
                transferable: true,
                includeTransferDetails: true,
                perPage: 256,
            }));

            commit(SET_ASSETS(resp, true));
            if (resp.length > 0) {
                const [initialAsset] = resp;
                const { transferDetails, symbol } = initialAsset;
                const blockchain = transferDetails?.find((d) => d.active);
                commit(SET_UI({
                    asset: symbol,
                    blockchain: blockchain?.blockchainName,
                } as Partial<TransferUI>, true));
            }
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during getting assets', { root: true });
            }
        }
        return Promise.resolve();
    },
    async getBalance({ state, dispatch }, accountId: ReturnType<typeof getBalance>) {
        try {
            if (state.ui.transferType === 'Accounts') {
                let allFromBalances: Balance[];
                const fromBalancesResult = await AccountsApi.privateGetBalances(new BalancesRequest({
                    accountId: accountId.payload,
                    placementName: state.ui.fromAccount.id,
                    perPage: 100,
                    page: 1,
                    includeTotal: true,
                }), true);
                const fromBalancesRes = fromBalancesResult.data;
                const fromBalancesHeaders = fromBalancesResult.headers;
                allFromBalances = [...fromBalancesRes];
                if (fromBalancesHeaders) {
                    let totalPages = 1 as number | undefined;
                    totalPages = parsePaginationHeaders(fromBalancesHeaders).totalPage;
                    if (totalPages && totalPages > 1) {
                        for (let i = 2; i <= totalPages; i += 1) {
                            // eslint-disable-next-line no-await-in-loop
                            const { data: fromBalancesResExtra } = await AccountsApi.privateGetBalances(new BalancesRequest({
                                accountId: accountId.payload,
                                placementName: state.ui.fromAccount.id,
                                perPage: 100,
                                page: i,
                            }));
                            allFromBalances = [...allFromBalances, ...fromBalancesResExtra];
                        }
                    }
                }
                state.ui.fromBalances = allFromBalances;

                let allToBalances: Balance[];
                const toBalancesResult = await AccountsApi.privateGetBalances(new BalancesRequest({
                    accountId: state.ui.toAccount.id,
                    placementName: state.ui.fromAccount.id,
                    perPage: 100,
                    page: 1,
                    includeTotal: true,
                }), true);
                const toBalancesRes = toBalancesResult.data;
                const toBalancesHeaders = toBalancesResult.headers;
                allToBalances = [...toBalancesRes];
                if (toBalancesHeaders) {
                    let totalPages = 1 as number | undefined;
                    totalPages = parsePaginationHeaders(toBalancesHeaders).totalPage;
                    if (totalPages && totalPages > 1) {
                        for (let i = 2; i <= totalPages; i += 1) {
                            // eslint-disable-next-line no-await-in-loop
                            const { data: toBalancesResExtra } = await AccountsApi.privateGetBalances(new BalancesRequest({
                                accountId: state.ui.toAccount.id,
                                placementName: state.ui.fromAccount.id,
                                perPage: 100,
                                page: i,
                            }));
                            allToBalances = [...allToBalances, ...toBalancesResExtra];
                        }
                    }
                }
                state.ui.toBalances = allToBalances;
            } else if (state.ui.transferType === 'Exchanges') {
                let allFromBalances: Balance[];
                const fromBalancesResult = await AccountsApi.privateGetBalances(new BalancesRequest({
                    accountId: accountId.payload,
                    placementName: state.ui.fromExchange.id,
                    perPage: 100,
                    page: 1,
                    includeTotal: true,
                }), true);
                const fromBalancesRes = fromBalancesResult.data;
                const fromBalancesHeaders = fromBalancesResult.headers;
                allFromBalances = [...fromBalancesRes];
                if (fromBalancesHeaders) {
                    let totalPages = 1 as number | undefined;
                    totalPages = parsePaginationHeaders(fromBalancesHeaders).totalPage;
                    if (totalPages && totalPages > 1) {
                        for (let i = 2; i <= totalPages; i += 1) {
                            // eslint-disable-next-line no-await-in-loop
                            const { data: fromBalancesResExtra } = await AccountsApi.privateGetBalances(new BalancesRequest({
                                accountId: accountId.payload,
                                placementName: state.ui.fromExchange.id,
                                perPage: 100,
                                page: i,
                            }));
                            allFromBalances = [...allFromBalances, ...fromBalancesResExtra];
                        }
                    }
                }
                state.ui.fromBalances = allFromBalances;

                let allToBalances: Balance[];
                const toBalancesResult = await AccountsApi.privateGetBalances(new BalancesRequest({
                    accountId: accountId.payload,
                    placementName: state.ui.toExchange.id,
                    perPage: 100,
                    page: 1,
                    includeTotal: true,
                }), true);
                const toBalancesRes = toBalancesResult.data;
                const toBalancesHeaders = toBalancesResult.headers;
                allToBalances = [...toBalancesRes];
                if (toBalancesHeaders) {
                    let totalPages = 1 as number | undefined;
                    totalPages = parsePaginationHeaders(toBalancesHeaders).totalPage;
                    if (totalPages && totalPages > 1) {
                        for (let i = 2; i <= totalPages; i += 1) {
                            // eslint-disable-next-line no-await-in-loop
                            const { data: toBalancesResExtra } = await AccountsApi.privateGetBalances(new BalancesRequest({
                                accountId: accountId.payload,
                                placementName: state.ui.toExchange.id,
                                perPage: 100,
                                page: i,
                            }));
                            allToBalances = [...allToBalances, ...toBalancesResExtra];
                        }
                    }
                }
                state.ui.toBalances = allToBalances;
            }
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during getting balances', { root: true });
            }
        }
    },
    async getTransferFees({ state, getters, dispatch }, activeAccountId: ReturnType<typeof getTransferFees>) {
        try {
            let payload: ITransferFeeRequestData;
            if (getters.GET_BLOCKCHAIN_NEEDED) {
                payload = {
                    amount: state.ui.quantity,
                    assetSymbol: getters.GET_ASSETS[state.ui.currentAssetIndex].symbol,
                    blockchainName: state.ui.blockchain,
                    destination: {
                        accountId: state.ui.transferType === 'Accounts' ? state.ui.toAccount.id : activeAccountId.payload,
                        placementName: state.ui.transferType === 'Accounts' ? state.ui.fromAccount.id : state.ui.toExchange.id,
                    },
                    source: {
                        accountId: activeAccountId.payload,
                        placementName: state.ui.transferType === 'Accounts' ? state.ui.fromAccount.id : state.ui.fromExchange.id,
                    },
                };
            } else {
                payload = {
                    amount: state.ui.quantity,
                    assetSymbol: getters.GET_ASSETS[state.ui.currentAssetIndex].symbol,
                    destination: {
                        accountId: state.ui.transferType === 'Accounts' ? state.ui.toAccount.id : activeAccountId.payload,
                        placementName: state.ui.transferType === 'Accounts' ? state.ui.fromAccount.id : state.ui.toExchange.id,
                    },
                    source: {
                        accountId: activeAccountId.payload,
                        placementName: state.ui.transferType === 'Accounts' ? state.ui.fromAccount.id : state.ui.fromExchange.id,
                    },
                };
            }
            const { data: res } = await WalletsApi.getTransferFees(new TransferFeeRequestData(payload));
            if (!(res instanceof EmptyResult)) {
                state.ui.fees = res;
            }
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during getting fees', { root: true });
            }
        }
    },
    async doTransfer({ state, dispatch, commit, getters }, props: ReturnType<typeof doTransfer>) {
        if (state.ui.transferType === 'Exchanges' && state.ui.fromExchange.id === 'Bitfinex' && state.ui.toExchange.id === 'OKX' && state.ui.asset === 'DASH') {
            await dispatch('Notificator/showErrorNotification', 'DASH Transfers from Bitfinex to OKX are disabled', { root: true });
        } else {
            const totp = await dispatch('Auth/getMFAToken', { type: MFA_ENROLL_FACTOR_TYPES.TOTP }, { root: true });
            try {
                commit(SET_LOADING_ON(undefined), { root: true });
                let payload: ITransferRequestData;
                if (getters.GET_BLOCKCHAIN_NEEDED) {
                    payload = {
                        assetSymbol: state.ui.asset,
                        blockchainName: state.ui.blockchain,
                        amount: state.ui.quantity,
                        feeSize: state.ui.feeSize,
                        totp,
                        source: {
                            accountId: props.payload.activeAccountId,
                            placementName: state.ui.transferType === 'Accounts' ? state.ui.fromAccount.id : state.ui.fromExchange.id,
                        },
                        destination: {
                            accountId: state.ui.transferType === 'Accounts' ? state.ui.toAccount.id : props.payload.activeAccountId,
                            placementName: state.ui.transferType === 'Accounts' ? state.ui.fromAccount.id : state.ui.toExchange.id,
                        },
                    };
                } else {
                    payload = {
                        assetSymbol: state.ui.asset,
                        amount: state.ui.quantity,
                        totp,
                        source: {
                            accountId: props.payload.activeAccountId,
                            placementName: state.ui.transferType === 'Accounts' ? state.ui.fromAccount.id : state.ui.fromExchange.id,
                        },
                        destination: {
                            accountId: state.ui.transferType === 'Accounts' ? state.ui.toAccount.id : props.payload.activeAccountId,
                            placementName: state.ui.transferType === 'Accounts' ? state.ui.fromAccount.id : state.ui.toExchange.id,
                        },
                    };
                }
                const { data: res } = await WalletsApi.transfer(new TransferRequestData(payload));
                const { data: transfer } = await WalletsApi.privateGetTransfer(new TransferRequest({
                    id: res.transferId,
                }));
                commit(UPDATE_TRANSFER(transfer.serialize()), { root: true });
                await router.replace('/Wallets');
            } catch (error) {
                if (error instanceof ApiError) {
                    await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
                } else {
                    await dispatch('Notificator/showErrorNotification', 'Error during performing transfer', { root: true });
                }
                await router.replace('/Wallets');
            } finally {
                commit(SET_LOADING_OFF(undefined), { root: true });
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
