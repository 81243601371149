// This file was autogenerated. Please do not change.
export interface IBorrowing {
    accountId: string;
    amount: string;
    assetSymbol: string;
    balanceId: string;
    maxAmount: string;
    placementName: string;
    placementTag: string;
    rate: string;
}

export default class Borrowing {
    readonly _accountId: string;

    /**
     * Example: A5Q98WZ7PDX
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _amount: number;

    /**
     * Example: 0
     */
    get amount(): number {
        return this._amount;
    }

    readonly _assetSymbol: string;

    /**
     * Example: BTC
     */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _balanceId: string;

    /**
     * Example: BL5LP5UP9VW
     */
    get balanceId(): string {
        return this._balanceId;
    }

    readonly _maxAmount: number;

    /**
     * Example: 0
     */
    get maxAmount(): number {
        return this._maxAmount;
    }

    readonly _placementName: string;

    /**
     * Example: ByBit
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _placementTag: string;

    /**
     * Example: BYBIT
     */
    get placementTag(): string {
        return this._placementTag;
    }

    readonly _rate: number;

    /**
     * Example: 0
     */
    get rate(): number {
        return this._rate;
    }

    constructor(props: IBorrowing) {
        this._accountId = props.accountId.trim();
        this._amount = Number(props.amount);
        this._assetSymbol = props.assetSymbol.trim();
        this._balanceId = props.balanceId.trim();
        this._maxAmount = Number(props.maxAmount);
        this._placementName = props.placementName.trim();
        this._placementTag = props.placementTag.trim();
        this._rate = Number(props.rate);
    }

    serialize(): IBorrowing {
        const data: IBorrowing = {
            accountId: this._accountId,
            amount: String(this._amount),
            assetSymbol: this._assetSymbol,
            balanceId: this._balanceId,
            maxAmount: String(this._maxAmount),
            placementName: this._placementName,
            placementTag: this._placementTag,
            rate: String(this._rate),
        };
        return data;
    }

    toJSON(): IBorrowing {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            amount: true,
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            balanceId: typeof this._balanceId === 'string' && !!this._balanceId.trim(),
            maxAmount: true,
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            placementTag: typeof this._placementTag === 'string' && !!this._placementTag.trim(),
            rate: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
