// This file was autogenerated. Please do not change.
export interface IRepayment {
    currency?: string;
    quantity?: number;
}

export default class Repayment {
    readonly _currency: string | undefined;

    get currency(): string | undefined {
        return this._currency;
    }

    readonly _quantity: number | undefined;

    get quantity(): number | undefined {
        return this._quantity;
    }

    constructor(props: IRepayment) {
        if (typeof props.currency === 'string') {
            this._currency = props.currency.trim();
        }
        if (typeof props.quantity === 'number') {
            this._quantity = props.quantity;
        }
    }

    serialize(): IRepayment {
        const data: IRepayment = {
        };
        if (typeof this._currency !== 'undefined') {
            data.currency = this._currency;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = this._quantity;
        }
        return data;
    }

    toJSON(): IRepayment {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            currency: !this._currency ? true : typeof this._currency === 'string',
            quantity: !this._quantity ? true : typeof this._quantity === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
