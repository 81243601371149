import ApiError from 'Entities/ApiError';
import CancelOrderRequest from 'Entities/orderRegistrator/CancelOrderRequest';
import PlaceOrderRequest from 'Entities/orderRegistrator/PlaceOrderRequest';
import QuickRepaymentRequest from 'Entities/orderRegistrator/QuickRepaymentRequest';
import Repayment from 'Entities/orderRegistrator/Repayment';
import SpotOrderView from 'Entities/orderRegistrator/SpotOrderView';
import AccountTradingCommission from 'Entities/privatePresenter/AccountTradingCommission';
import FuturesFundingRecord from 'Entities/privatePresenter/FuturesFundingRecord';
import FuturesFundingRecordsRequest from 'Entities/privatePresenter/FuturesFundingRecordsRequest';
import FuturesOrderPresenter from 'Entities/privatePresenter/FuturesOrderPresenter';
import FuturesOrderRequest from 'Entities/privatePresenter/FuturesOrderRequest';
import FuturesOrdersRequest from 'Entities/privatePresenter/FuturesOrdersRequest';
import FuturesPosition from 'Entities/privatePresenter/FuturesPosition';
import FuturesPositionsRequest from 'Entities/privatePresenter/FuturesPositionsRequest';
import FuturesTrade from 'Entities/privatePresenter/FuturesTrade';
import FuturesTradeRequest from 'Entities/privatePresenter/FuturesTradeRequest';
import FuturesTradesRequest from 'Entities/privatePresenter/FuturesTradesRequest';
import Leverage from 'Entities/privatePresenter/Leverage';
import LeverageRequest from 'Entities/privatePresenter/LeverageRequest';
import SpotOrderPresenter from 'Entities/privatePresenter/SpotOrderPresenter';
import SpotOrderRequest from 'Entities/privatePresenter/SpotOrderRequest';
import SpotOrdersRequest from 'Entities/privatePresenter/SpotOrdersRequest';
import SpotTrade from 'Entities/privatePresenter/SpotTrade';
import SpotTradeRequest from 'Entities/privatePresenter/SpotTradeRequest';
import SpotTradesRequest from 'Entities/privatePresenter/SpotTradesRequest';
import TradingCommissionRateRequest from 'Entities/privatePresenter/TradingCommissionRateRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class TradingApi {
    static async privateOrdersCancelOrder(cancelorderrequest: CancelOrderRequest, withHeaders?: boolean): Promise<{ data: string; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...cancelorderrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateOrdersCancelOrder', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/orders/cancelOrder`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(cancelorderrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: await res.json(), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateOrdersCancelOrder', { response: res, data: result, fields: [JSON.stringify({ cancelorderrequest })] });
    }

    static async privateOrdersPlaceOrder(placeorderrequest: PlaceOrderRequest, withHeaders?: boolean): Promise<{ data: SpotOrderView; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...placeorderrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateOrdersPlaceOrder', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/orders/placeOrder`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(placeorderrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new SpotOrderView(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateOrdersPlaceOrder', { response: res, data: result, fields: [JSON.stringify({ placeorderrequest })] });
    }

    static async privateOrdersQuickRepayment(quickrepaymentrequest: QuickRepaymentRequest, withHeaders?: boolean): Promise<{ data: Repayment[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...quickrepaymentrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateOrdersQuickRepayment', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/orders/quickRepayment`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(quickrepaymentrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: (await res.json()).map((r: any) => new Repayment(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateOrdersQuickRepayment', { response: res, data: result, fields: [JSON.stringify({ quickrepaymentrequest })] });
    }

    static async privateFuturesGetCurrentLeverage(leveragerequest: LeverageRequest, withHeaders?: boolean): Promise<{ data: Leverage[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...leveragerequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesGetCurrentLeverage', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/futures/getCurrentLeverage`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(leveragerequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Leverage(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateFuturesGetCurrentLeverage', { response: res, data: result, fields: [JSON.stringify({ leveragerequest })] });
    }

    static async privateFuturesGetFundingRecords(futuresfundingrecordsrequest: FuturesFundingRecordsRequest, withHeaders?: boolean): Promise<{ data: FuturesFundingRecord[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...futuresfundingrecordsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesGetFundingRecords', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/futures/getFundingRecords`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(futuresfundingrecordsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new FuturesFundingRecord(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateFuturesGetFundingRecords', { response: res, data: result, fields: [JSON.stringify({ futuresfundingrecordsrequest })] });
    }

    static async privateFuturesGetOrder(futuresorderrequest: FuturesOrderRequest, withHeaders?: boolean): Promise<{ data: FuturesOrderPresenter; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...futuresorderrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesGetOrder', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/futures/getOrder`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(futuresorderrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new FuturesOrderPresenter(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateFuturesGetOrder', { response: res, data: result, fields: [JSON.stringify({ futuresorderrequest })] });
    }

    static async privateFuturesGetOrders(futuresordersrequest: FuturesOrdersRequest, withHeaders?: boolean): Promise<{ data: FuturesOrderPresenter[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...futuresordersrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesGetOrders', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/futures/getOrders`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(futuresordersrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new FuturesOrderPresenter(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateFuturesGetOrders', { response: res, data: result, fields: [JSON.stringify({ futuresordersrequest })] });
    }

    static async privateFuturesGetPosition(futurespositionsrequest: FuturesPositionsRequest, withHeaders?: boolean): Promise<{ data: FuturesPosition; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...futurespositionsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesGetPosition', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/futures/getPosition`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(futurespositionsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new FuturesPosition(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateFuturesGetPosition', { response: res, data: result, fields: [JSON.stringify({ futurespositionsrequest })] });
    }

    static async privateFuturesGetPositions(futurespositionsrequest: FuturesPositionsRequest, withHeaders?: boolean): Promise<{ data: FuturesPosition[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...futurespositionsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesGetPositions', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/futures/getPositions`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(futurespositionsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new FuturesPosition(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateFuturesGetPositions', { response: res, data: result, fields: [JSON.stringify({ futurespositionsrequest })] });
    }

    static async privateFuturesGetTrade(futurestraderequest: FuturesTradeRequest, withHeaders?: boolean): Promise<{ data: FuturesTrade; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...futurestraderequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesGetTrade', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/futures/getTrade`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(futurestraderequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new FuturesTrade(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateFuturesGetTrade', { response: res, data: result, fields: [JSON.stringify({ futurestraderequest })] });
    }

    static async privateFuturesGetTrades(futurestradesrequest: FuturesTradesRequest, withHeaders?: boolean): Promise<{ data: FuturesTrade[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...futurestradesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesGetTrades', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/futures/getTrades`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(futurestradesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new FuturesTrade(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateFuturesGetTrades', { response: res, data: result, fields: [JSON.stringify({ futurestradesrequest })] });
    }

    static async privateGetSpotOrder(spotorderrequest: SpotOrderRequest, withHeaders?: boolean): Promise<{ data: SpotOrderPresenter; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spotorderrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetSpotOrder', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getSpotOrder`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spotorderrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SpotOrderPresenter(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateGetSpotOrder', { response: res, data: result, fields: [JSON.stringify({ spotorderrequest })] });
    }

    static async privateGetSpotOrders(spotordersrequest: SpotOrdersRequest, withHeaders?: boolean): Promise<{ data: SpotOrderPresenter[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spotordersrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetSpotOrders', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getSpotOrders`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spotordersrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new SpotOrderPresenter(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateGetSpotOrders', { response: res, data: result, fields: [JSON.stringify({ spotordersrequest })] });
    }

    static async privateGetSpotTrade(spottraderequest: SpotTradeRequest, withHeaders?: boolean): Promise<{ data: SpotTrade; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spottraderequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetSpotTrade', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getSpotTrade`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spottraderequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SpotTrade(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateGetSpotTrade', { response: res, data: result, fields: [JSON.stringify({ spottraderequest })] });
    }

    static async privateGetSpotTrades(spottradesrequest: SpotTradesRequest, withHeaders?: boolean): Promise<{ data: SpotTrade[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spottradesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetSpotTrades', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getSpotTrades`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spottradesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new SpotTrade(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateGetSpotTrades', { response: res, data: result, fields: [JSON.stringify({ spottradesrequest })] });
    }

    static async privateGetSpotTradingCommissionRate(tradingcommissionraterequest: TradingCommissionRateRequest, withHeaders?: boolean): Promise<{ data: AccountTradingCommission; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...tradingcommissionraterequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetSpotTradingCommissionRate', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getSpotTradingCommissionRate`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(tradingcommissionraterequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new AccountTradingCommission(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateGetSpotTradingCommissionRate', { response: res, data: result, fields: [JSON.stringify({ tradingcommissionraterequest })] });
    }

    static async privateGetTradingCommissionRate(tradingcommissionraterequest: TradingCommissionRateRequest, withHeaders?: boolean): Promise<{ data: AccountTradingCommission; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...tradingcommissionraterequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetTradingCommissionRate', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getTradingCommissionRate`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(tradingcommissionraterequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new AccountTradingCommission(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateGetTradingCommissionRate', { response: res, data: result, fields: [JSON.stringify({ tradingcommissionraterequest })] });
    }

    static async privateSpotGetOrder(spotorderrequest: SpotOrderRequest, withHeaders?: boolean): Promise<{ data: SpotOrderPresenter; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spotorderrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateSpotGetOrder', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/spot/getOrder`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spotorderrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SpotOrderPresenter(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateSpotGetOrder', { response: res, data: result, fields: [JSON.stringify({ spotorderrequest })] });
    }

    static async privateSpotGetOrders(spotordersrequest: SpotOrdersRequest, withHeaders?: boolean): Promise<{ data: SpotOrderPresenter[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spotordersrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateSpotGetOrders', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/spot/getOrders`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spotordersrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new SpotOrderPresenter(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateSpotGetOrders', { response: res, data: result, fields: [JSON.stringify({ spotordersrequest })] });
    }

    static async privateSpotGetTrade(spottraderequest: SpotTradeRequest, withHeaders?: boolean): Promise<{ data: SpotTrade; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spottraderequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateSpotGetTrade', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/spot/getTrade`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spottraderequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SpotTrade(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateSpotGetTrade', { response: res, data: result, fields: [JSON.stringify({ spottraderequest })] });
    }

    static async privateSpotGetTrades(spottradesrequest: SpotTradesRequest, withHeaders?: boolean): Promise<{ data: SpotTrade[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spottradesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateSpotGetTrades', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/spot/getTrades`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spottradesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new SpotTrade(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateSpotGetTrades', { response: res, data: result, fields: [JSON.stringify({ spottradesrequest })] });
    }

    static async privateSpotGetTradingCommissionRate(tradingcommissionraterequest: TradingCommissionRateRequest, withHeaders?: boolean): Promise<{ data: AccountTradingCommission; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...tradingcommissionraterequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateSpotGetTradingCommissionRate', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/spot/getTradingCommissionRate`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(tradingcommissionraterequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new AccountTradingCommission(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Trading/privateSpotGetTradingCommissionRate', { response: res, data: result, fields: [JSON.stringify({ tradingcommissionraterequest })] });
    }
}
