// This file was autogenerated. Please do not change.
export interface ICollateral {
    accountId: string;
    assetSymbol: string;
    borrowingAmount: string;
    borrowingMaxAmount: string;
    hourlyRate: string;
    placementTag: string;
}

export default class Collateral {
    readonly _accountId: string;

    /**
     * Example: AQVW2VSEUU1
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _assetSymbol: string;

    /**
     * Example: USDT
     */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _borrowingAmount: number;

    /**
     * Example: 100.001
     */
    get borrowingAmount(): number {
        return this._borrowingAmount;
    }

    readonly _borrowingMaxAmount: number;

    /**
     * Example: 100.001
     */
    get borrowingMaxAmount(): number {
        return this._borrowingMaxAmount;
    }

    readonly _hourlyRate: number;

    /**
     * Example: 0.001
     */
    get hourlyRate(): number {
        return this._hourlyRate;
    }

    readonly _placementTag: string;

    /**
     * Example: BYBIT
     */
    get placementTag(): string {
        return this._placementTag;
    }

    constructor(props: ICollateral) {
        this._accountId = props.accountId.trim();
        this._assetSymbol = props.assetSymbol.trim();
        this._borrowingAmount = Number(props.borrowingAmount);
        this._borrowingMaxAmount = Number(props.borrowingMaxAmount);
        this._hourlyRate = Number(props.hourlyRate);
        this._placementTag = props.placementTag.trim();
    }

    serialize(): ICollateral {
        const data: ICollateral = {
            accountId: this._accountId,
            assetSymbol: this._assetSymbol,
            borrowingAmount: String(this._borrowingAmount),
            borrowingMaxAmount: String(this._borrowingMaxAmount),
            hourlyRate: String(this._hourlyRate),
            placementTag: this._placementTag,
        };
        return data;
    }

    toJSON(): ICollateral {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            borrowingAmount: true,
            borrowingMaxAmount: true,
            hourlyRate: true,
            placementTag: typeof this._placementTag === 'string' && !!this._placementTag.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
